

<app-modal-header
  (close)="close()">
  <span slot="title">{{ 'new-app-version-modal.title' | translate }}</span>
</app-modal-header>

<div class="modal-body">

  <p>
    {{ 'new-app-version-modal.text' | translate }}
  </p>

</div>

<div class="modal-footer">

  <button
    pButton
    (click)="onReaload()"
    [label]="'new-app-version-modal.update-btn.label' | translate"
    class="p-button-success">
  </button>

</div>