import { Injector } from "@angular/core";
import { UtilsService } from "../services/utils.service";
import { EventState, EventStateName } from "./event/event-states.enum";

const enumsToCheck = [
  { enum: EventState, map: EventStateName }
];




const injector = Injector.create({
  providers: [ { provide: UtilsService } ]
});

export const checkEnumMaps = () => {
  for (let x of enumsToCheck) {
    for (let i of Object.keys(x.enum)) {
      if (x.map.get(<never>i) === undefined) {
        console.error(`Enum ${i} not mapped! io12pu3p098ew23`);
      }
    }
  }
}
